import React, { Component } from 'react';

import { Alert } from 'react-bootstrap';
import Axios from 'axios';
import numeral from 'numeral';
import { saveAs } from 'file-saver';

export const UtilsContext = React.createContext();

if (!numeral.locales['pt-br']) {
  numeral.register('locale', 'pt-br', {
    delimiters: {
      thousands: '.',
      decimal: ',',
    },
    abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't',
    },
    ordinal: function (number) {
      return number === 1 ? 'º' : 'º';
    },
    currency: {
      symbol: 'R$ ',
    },
  });
  numeral.locale('pt-br');
}

export const currency = (pValor, pSymbol = true) => {
  return numeral(pValor).format(pSymbol ? '$0,0.00' : '0,0.00');
};

export const unCurrency = (pValor) => {
  var rValor = numeral(pValor).value();
  return rValor;
};

export const fileSize = (valor, binary = true) => {
  const format = (x) => Math.round(x * 10) / 10;

  if (valor < (binary ? 103 : 100)) return `${format(valor)} B`;

  valor /= binary ? 1024 : 1000;
  if (valor < 1000) return `${format(valor)} ${binary ? 'KiB' : 'KB'}`;

  valor /= binary ? 1024 : 1000;
  if (valor < 1000) return `${format(valor)} ${binary ? 'MiB' : 'MB'}`;

  valor /= binary ? 1024 : 1000;
  return `${format(valor)} ${binary ? 'GiB' : 'GB'}`;
};

export const formatCnpjCpf = (value) => {
  const cnpjCpf = value.replace(/\D/g, '');

  if (cnpjCpf.length === 11) {
    return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  }

  return cnpjCpf.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
    '$1.$2.$3/$4-$5'
  );
};

export const findByType = (children, component) => {
  const result = [];
  const type = component.displayName || component.name;

  React.Children.forEach(children, (child) => {
    const childType =
      child && child.type && (child.type.displayName || child.type.name);
    if (type === childType) {
      result.push(child);
    }
  });

  return result;
};

// Previne erros de render
export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Atualiza o state para que a próxima renderização mostre a UI alternativa.
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <Alert variant="danger">Algo deu errado.</Alert>;
    }

    return this.props.children;
  }
}

export const downloadFile = async (id, name) => {
  const { data } = await Axios.post(
    process.env.REACT_APP_API_DOWNLOAD,
    { id, name },
    { responseType: 'blob' }
  );
  saveAs(data, name);
  return true;
};


/** Mutation cache control  */
export const apolloCache = {
  /**
   * Create a configuration to add the response in the cache
   * @param {Apollo.DocumentNode} document    The query of the cache to be updated
   * @param {string}              dataField   The field in the GraphQL response that contains the dataField
   * @param {string}              targetField The filed in the query that holds the data
   * @return                                  An object to be passed as options of a `useMutation`
   */
  add(document, dataField, targetField) {
    return {
      update(client, { data: responseData }) {
        if (!responseData) return client;

        const data = client.readQuery({ query: document });
        client.writeQuery({
          query: document,
          data: {
            [targetField]: [...data[targetField], responseData[dataField]],
          },
        });
      },
    };
  },
  /**
   * Create a configuration to remove the cached item that has the response's `id`
   * @param {Apollo.DocumentNode} document    The query of the cache to be updated
   * @param {string}              dataField   The field in the GraphQL response that contains the dataField
   * @param {string}              targetField The filed in the query that holds the data
   * @return                                  An object to be passed as options of a `useMutation`
   */
  remove(document, dataField, targetField) {
    return {
      update(client, { data: responseData }) {
        if (!responseData) return client;

        const data = client.readQuery({ query: document });
        client.writeQuery({
          query: document,
          data: {
            [targetField]: data[targetField].filter(
              ({ id }) => id !== responseData[dataField].id
            ),
          },
        });
      },
    };
  },
  /**
   * Create a configuration to edit the cached item that has the response's `id`
   * @param {Apollo.DocumentNode} document    The query of the cache to be updated
   * @param {string}              dataField   The field in the GraphQL response that contains the dataField
   * @param {string}              targetField The filed in the query that holds the data
   * @return                                  An object to be passed as options of a `useMutation`
   */
  edit(document, dataField, targetField) {
    return {
      update(client, { data: responseData }) {
        if (!responseData) return client;

        const data = client.readQuery({ query: document });
        client.writeQuery({
          query: document,
          data: {
            [targetField]: data[targetField].map((item) =>
              item.id === responseData[dataField].id
                ? responseData[dataField]
                : item
            ),
          },
        });
      },
    };
  },
};

/**
 * Turns an `utils.apolloCache` option into a subscription cache control
 * @param apolloCacheObject {object}    An item of `apolloCache`
 * @param callback          {function?} A function to be called after the cache change
 * @return                              An object to be passed as options of a `useSubscription`
 */
export function subscriptionCache(apolloCacheObject, callback) {
  return {
    onSubscriptionData({ client, subscriptionData }) {
      apolloCacheObject.update(client, subscriptionData);
      if (typeof callback == 'function') callback(subscriptionData.data);
    },
  };
}
