import React, { Suspense, useLayoutEffect, useRef, useState } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import Loadable from 'react-loadable';
import NotificationSystem from 'react-notification-system';
import { ApolloProvider } from '@apollo/react-hooks';
import 'font-awesome/scss/font-awesome.scss';
import 'react-dropzone-component/styles/filepicker.css';
import 'dropzone/dist/min/dropzone.min.css';
import "react-datepicker/dist/react-datepicker.css";

import { apolloClient } from '../services';
import { UtilsContext } from '../utils';

import Loader from './layout/Loader';
import Aux from '../hoc/_Aux';
import ScrollToTop from './layout/ScrollToTop';
import routes from '../UnauthenticatesRoutes';

const AdminLayout = Loadable({
    loader: () => import('./layout/AdminLayout'),
    loading: Loader,
});

const App = () => {
    const [_session, setSession] = useState([]);
    const [menusSistema, setMenusSistema] = useState([]);
    const notificationSystem = useRef();

    const addNotification = (_message) => {
        notificationSystem.current.addNotification(_message);
    };

    const addSession = (key, value, local = false) => {
        setSession([..._session, ...[{ [key]: value }]]);
        if (local) {
            localStorage.setItem(key, value);
        }
    };

    const removeSession = (key, value, local = false) => {
        setSession([..._session.filter((k) => k !== key)]);
        localStorage.removeItem(key, value);
    };

    const getSession = (key) => {
        const i = _session.findIndex((s) => s[key] !== undefined);
        if (i >= 0) {
            return _session[i][key];
        } else {
            const value = localStorage.getItem(key);
            if (value) {
                setSession([..._session, ...[{ [key]: value }]]);
                return value;
            }
        }
        return null;
    };

    const logOutUser = () => {
        localStorage.clear();
        window.location.href = '/idsystems';
    };

    const menu = routes.map((route, index) => {
        return route.component ? (
            <Route
                key={index}
                path={route.path}
                exact={route.exact}
                name={route.name}
                render={(props) => <route.component {...props} />}
            />
        ) : null;
    });

    useLayoutEffect(() => {
        const token = localStorage.getItem('accessToken');
        if (!token && !window.location.href.toString().includes('login')) {
            // console.log('auth login',window.location.href.toString().includes('login'))
            window.location.href = '/auth/login';
        }

        return () => {
            console.log('bye bye!');
        };
    }, []);

    return (
        <Aux>
            <UtilsContext.Provider
                value={{
                    addNotification,
                    addSession,
                    getSession,
                    removeSession,
                    logOutUser,
                    setMenusSistema,
                    menusSistema,
                }}
            >
                <ApolloProvider client={apolloClient}>
                    <ScrollToTop>
                        <Suspense fallback={<Loader />}>
                            <Switch>
                                {menu}
                                <Route path="/" component={AdminLayout} />
                            </Switch>
                        </Suspense>
                    </ScrollToTop>
                    <NotificationSystem ref={notificationSystem} />
                </ApolloProvider>
            </UtilsContext.Provider>
        </Aux>
    );
};

export default App;
