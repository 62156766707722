import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { WebSocketLink } from 'apollo-link-ws';
import config from '../config';
import { getMainDefinition } from 'apollo-utilities';
import { setContext } from '@apollo/client/link/context';
import { split } from 'apollo-link';

/*** start apollo ***/

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_GRAPHQL,
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_API_WS,
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLink
);

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('accessToken');
  return {
    headers: {
      ...headers,
      Authorization: token ? 'Bearer ' + token : '',
    },
  };
});

const apolloClient = new ApolloClient({
  connectToDevtools: true,
  link: authLink.concat(link),
  cache: new InMemoryCache(),
});

/*** end apollo ***/

const graphQLErrors = (eObject) => {
  try {
    let _error;
    if (eObject.graphQLErrors !== undefined) {
      eObject.graphQLErrors.map((d, i) => {
        _error = d.extensions.exception;
      });
    }

    if (
      (!_error || Object.keys(_error).length === 0) &&
      eObject.networkError !== undefined &&
      eObject.networkError !== null
    ) {
      _error = {};
      eObject.networkError.result.errors.map((d, i) => {
        const arr = d.message.match(/\$(.*?)\"/g) || [''];
        const k = arr[0].replace('"', '').replace('$', '');
        _error[k] = 'Verifique o valor informado';
      });
    }
    if (!_error || Object.keys(_error).length === 0) {
      const usuario = eObject.message.split(':')[1].trim();
      _error = { usuario };
    }
    delete _error['stacktrace'];

    function normilizeErrors(stacktrace, title) {
      let retObj = {};
      const addError = (stacktrace, title) => {
        retObj = { ...retObj, ...normilizeErrors(stacktrace, title) };
      };

      if (stacktrace.hasOwnProperty('errors')) {
        addError(stacktrace.errors, title);
      } else if (stacktrace.hasOwnProperty('message')) {
        if (typeof stacktrace.message == 'object') {
          addError(stacktrace.message, title);
        } else {
          retObj[title] = stacktrace.message;
        }
      } else {
        Object.keys(stacktrace).forEach((e) => {
          if (stacktrace[e]) {
            let tmpTitle = title || e;
            if (typeof stacktrace[e] == 'object') {
              addError(stacktrace[e], tmpTitle);
            } else {
              retObj[tmpTitle] = stacktrace[e];
            }
          }
        });
      }

      return retObj;
    }

    return normilizeErrors(_error);
  } catch (e) {}
};

export { apolloClient, graphQLErrors };
